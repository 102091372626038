import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { ApplicationConfigService } from '../../../configs/application-config.service';
import { GenericService } from '../generic/generic.service';
import { ITypology } from '../../../shared/models/typology/typology.model';
import { ITypologyService } from '../../../shared/interface/typology/typology-interface.service';

export type EntityResponseType = HttpResponse<any>;

@Injectable({
  providedIn: 'root',
})
export class TypologyService extends GenericService<ITypology> implements ITypologyService {
    constructor(
      httpClient: HttpClient,
      applicationConfigService: ApplicationConfigService
  ) {
        super(httpClient,applicationConfigService,"typology");
    }
}