import { AdminGuard } from '../../core/guards/admin.guard';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContentComponent } from "../../shared/components/layout/content/content.component";

const routes: Routes = [
  {
    path: 'dashboard',
    component: ContentComponent,
    canActivate: [AdminGuard],
    children: [
      { path: 'administration', loadChildren: () => import('./administration/administration.module').then(mod => mod.AdministrationModule) },
      { path: 'media-management', loadChildren: () => import('./media-management/media-management.module').then(mod => mod.MediaManagementModule) },
      { path: 'metrics', loadChildren: () => import('./metrics/metrics.module').then(mod => mod.MetricsModule) },
      { path: 'product-cloud', loadChildren: () => import('./product-cloud/product-cloud.module').then(mod => mod.ProductCloudModule) },
      { path: 'pochet', loadChildren: () => import('./../pochet/pochet.module').then(mod => mod.PochetModule) }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class DashboardRoutingModule { }