import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import { Observable } from "rxjs";
@Injectable({
    providedIn: "root",
})
export class AdminGuard  {
    constructor(private router: Router) {}

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        const user = this.getUserFromLocalStorage();
        if (!user || !this.isValidUser(user)) {
            this.router.navigate(["/login"]);
            return false;
        }
        return true;
    }

    private getUserFromLocalStorage(): any {
        return JSON.parse(localStorage.getItem("user"));
    }

    private isValidUser(user: any): boolean {
        return user && Object.keys(user).length > 0;
    }
}