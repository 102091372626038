import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { AuthenticationService } from "./../../../../../core/services/authentication/authentication.service";

@Component({
    selector: "app-my-account",
    templateUrl: "./my-account.component.html",
    styleUrls: ["./my-account.component.scss"],
})
export class MyAccountComponent implements OnInit,OnDestroy {
    public login: string;
    public role: string;

    constructor(
        public router: Router,
        private authSvc: AuthenticationService
    ) {}

    ngOnInit() {
        this.login = localStorage.getItem('userName');
        this.role  = localStorage.getItem('role');
    }

    ngOnDestroy(): void {
        window.location.reload();
    }

    logout(): void {
        this.performLogout().subscribe({
            next: () => {
                this.authSvc.logout();
                this.router.navigateByUrl('/login');
            },
            error: (err) => {
                console.error('Une erreur est survenue lors de la déconnexion :', err);
            }
        });
    }

    private performLogout(): Observable<void> {
        return new Observable<void>(observer => {
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            localStorage.removeItem('userName');
            localStorage.removeItem('role');
            localStorage.removeItem('scanGeolocAuthorize');
            localStorage.removeItem('redirectUrl');
            observer.next();
            observer.complete();
        });
    }
}
