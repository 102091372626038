<div class="d-flex justify-content-between mt-3">
    {{ 'common.totalNumberOf' | translate }} {{ listingTitle | translate }} {{ totalElementCount | thousandSeparator }} 
    <div *ngIf="totalElementCount > pageSize">
        <nav class="m-b-30">
            <ul class="pagination justify-content-end pagination-primary">
                <li class="page-item" [class.disabled]="currentPage === 1 ? 'disabled' : ''">
                    <button class="page-link"  (click)="onChangePage(currentPage - 1)">{{ 'common.previous' | translate }}</button>
                </li>
                <li class="page-item" *ngFor="let i of numbersArray" [class.active]="i === currentPage">
                    <button class="page-link" (click)="onChangePage(i)">{{i}}</button>
                </li>
                <li class="page-item" [class.disabled]="currentPage === nbPages ? 'disabled' : ''">
                    <button class="page-link" (click)="onChangePage(currentPage + 1)">{{ 'common.next' | translate }}</button>
                </li>
            </ul>
        </nav>
    </div>
</div>
