import { Injectable } from '@angular/core';
import { environment } from './environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ApplicationConfigService {

    private apiEndpoint = "";

    constructor() {
        this.apiEndpoint = environment.apiUrl;
    }
  
    getEndpointFor(): string {
        return this.apiEndpoint;
    }

    setEndpointFor(url: string) {
        this.apiEndpoint = url;
    }
}