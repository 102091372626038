import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'thousandSeparator'
})
export class ThousandSeparatorPipe implements PipeTransform {
    transform(value: number): string {
        if (isNaN(value)) {
            return '';
        }
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    }
}
