import { Injectable } from "@angular/core";
import jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root',
})
export class UserService {

    /**
     * Collect Customer Id
     * 
     * @return string 
     */
    getClientId() : string{
        const token = localStorage.getItem('token');
        if (token){
            return this.getDecodedAccessToken(token)['IdClient'];
        }
        return '';
    }

    /**
     * Collect Customer Login
     * 
     * @return string 
     */
    getLoginClient() : number{
        const token = localStorage.getItem('token');
        if (token){
            return this.getDecodedAccessToken(token)['email'];
        }
        return -1;
    }

    /**
     * 
     */
    getRoleClient() : any{
        const token = localStorage.getItem('token');
        if (token) {
            return this.getDecodedAccessToken(token)['role'];
        }
    }

    /**
     * Retrieve Access token
     * 
     * @return string 
     */
    private getDecodedAccessToken(token: string): any {
        try {
            return jwt_decode(token);
        } catch(Error) {
            return null;
        }
    }
}