import { Directive, ElementRef, AfterViewInit, Renderer2, Input } from '@angular/core';

@Directive({
  selector: '[appCheckKpiText]'
})
export class CheckKpiTextDirective implements AfterViewInit{

  @Input() maxWidth: number;
  @Input() className: string;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngAfterViewInit() {
    const spanElement = this.el.nativeElement;
    if (spanElement && this.maxWidth && this.className) {
      if (spanElement.offsetWidth < this.maxWidth) {
        this.renderer.addClass(spanElement, this.className);
      }
    }
  }

}
