import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { ApplicationConfigService } from '../../../configs/application-config.service';
import { GenericService } from '../generic/generic.service';
import { IBrandConfiguration } from '../../../shared/models/brand-configuration/brand-configuration.model';
import { IBrandConfigurationService } from '../../../shared/interface/brand-configuration/brand-configuration-interface.service';

export type EntityResponseType = HttpResponse<any>;

@Injectable({
  providedIn: 'root',
})
export class BrandConfigurationService extends GenericService<IBrandConfiguration> implements IBrandConfigurationService {
    constructor(
      httpClient: HttpClient,
      applicationConfigService: ApplicationConfigService
  ) {
        super(httpClient,applicationConfigService,"brand-configuration");
    }
}