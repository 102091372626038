import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { ApplicationConfigService } from '../../../configs/application-config.service';
import { GenericService } from '../generic/generic.service';
import { IBrandDomain } from '../../../shared/models/brand-domain/brand-domain.model';
import { IBrandDomainService } from '../../../shared/interface/brand-domain/brand-domain-interface.service';
import { IMarketingLink } from 'src/app/shared/models/marketing-link/marketing-link.model';
import { Observable } from 'rxjs';

export type EntityResponseType = HttpResponse<any>;

@Injectable({
  providedIn: 'root',
})
export class BrandDomainService extends GenericService<IBrandDomain> implements IBrandDomainService {
    constructor(
      httpClient: HttpClient,
      applicationConfigService: ApplicationConfigService
  ) {
        super(httpClient,applicationConfigService,"brand-domain");
    }

    getMarketingLinks(): Observable<IMarketingLink[]> {
      return this.httpClient.get<IMarketingLink[]>(`${this.resourceUrl}/get-marketing-links`);
  }
}