import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { ApplicationConfigService } from '../../../configs/application-config.service';
import { GenericService } from '../generic/generic.service';
import { IAggregationScript } from '../../../shared/models/aggregation-script/aggregation-script.model';
import { IAggregationScriptService } from '../../../shared/interface/aggregation-script/aggregation-script-interface.service';
import { IAccountService } from '../../../shared/interface/account/account-interface.service';
import { IUser } from '../../../shared/models/application-user/user.model';
import { IPasswordConfirmation } from '../../../shared/models/account/password-confirmation.model';
import { IRole } from '../../../shared/models/application-user/role.model';
import { Observable, map } from 'rxjs';

export type EntityResponseType = HttpResponse<any>;

@Injectable({
  providedIn: 'root',
})
export class AccountService extends GenericService<IUser> implements IAccountService {
    constructor(
        httpClient: HttpClient,
        applicationConfigService: ApplicationConfigService
    ) {
        super(httpClient,applicationConfigService,"account");
    }

    createWithRole(user: IUser): Observable<HttpResponse<IUser>> {
        const headers = { 'Content-Type': 'application/json' };
        return this.httpClient
            .post<IUser>(`${this.resourceUrl}/register-with-role `, user, {
                headers: headers,
                observe: 'response',
            })
           .pipe(
            map((response: HttpResponse<IUser>) => {
                return response;
            })
        );
    }

    getAllUsers(): Observable<any> {
        return this.httpClient.get<any>(`${this.resourceUrl}/get-all-users`);
    }

    getRoles(): Observable<IRole[]>{
        return this.httpClient.get<any>(`${this.resourceUrl}/get-all-roles`);
    }

    getUsers(): Observable<IUser[]> {
        return this.httpClient.get<IUser[]>(`${this.resourceUrl}/get-users`);
    }

    resetPasswordRequest(mail: string) {
		return this.httpClient.post<any>(`${this.resourceUrl}/reset-password-request/${mail}/${localStorage.getItem('lang')}`,null, { observe: 'response' });
    }

    resetPasswordValidation(cryptedToken: string) {
        let filterParams: HttpParams = new HttpParams();
        filterParams = filterParams.set("cryptedToken", cryptedToken);
		return this.httpClient.get<any>(`${this.resourceUrl}/reset-password-validation`,{
            params: filterParams,
          });
    }

    resetPasswordDone(cryptedToken: string) {
        let filterParams: HttpParams = new HttpParams();
        filterParams = filterParams.set("cryptedToken", cryptedToken);
		return this.httpClient.get<any>(`${this.resourceUrl}/reset-password-done`,{
            params: filterParams,
          });
    }
    
    resetPasswordConfirmation(confirmation: IPasswordConfirmation) {
        const headers = { 'Content-Type': 'application/json' };
        confirmation.locale = localStorage.getItem('lang')
        return this.httpClient
            .put<IPasswordConfirmation>(`${this.resourceUrl}/reset-password-confirmation`, confirmation, {
                headers: headers,
                observe: 'response',
            })
           .pipe(
            map((response: HttpResponse<any>) => {
                return response;
            })
        );
    }
}