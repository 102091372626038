import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, tap } from "rxjs";
import { ApplicationConfigService } from "./../../configs/application-config.service";
import { AuthenticationService } from "./authentication/authentication.service";
import { Login } from "./../../shared/models/login/login.model";

@Injectable({
    providedIn: 'root',
})
export class LoginService {
    protected resourceUrl = this.applicationConfigService.getEndpointFor().concat('api/account/login');

    constructor(
        private httpClient: HttpClient,
        private authSvc: AuthenticationService,
        protected applicationConfigService: ApplicationConfigService
    ){}

    /**
     * Connects to the server with the provided login credentials.
     * 
     * @param string login 
     * @param string pwd 
     * 
     * @returns An Observable emitting the response of the login request.
     */
    connect(login:string, pwd: string):Observable<Login>{
        return this.httpClient.post<Login>(
            `${this.resourceUrl}`,{
                email: login,
                password: pwd
            }
        ).pipe(
            tap((response) => {
                if(localStorage.getItem('lang') == null) {
                    localStorage.setItem('lang', navigator.language);
                }
                localStorage.setItem("user", JSON.stringify({
                    email: login,
                    password: pwd
                }));
                localStorage.setItem('token', response.token);
                localStorage.setItem('userName', response['userName']);
                localStorage.setItem('permissions', response['permissions']);
                localStorage.setItem('role', response['role']);
                localStorage.setItem("tokenExpiration", response['tokenExpireDate']);
                this.authSvc.login();
            })
        );
    }
}

