<div class="media profile-media">
    <div class="media-body">
        <span>{{ login }}</span>
        <p class="mb-0 font-roboto">{{ role }} 
            <i class="middle fa fa-angle-down"></i>
        </p>
    </div>
</div>
<ul class="profile-dropdown onhover-show-div">
    <li (click)="logout()">
        <app-feather-icons [icon]="'log-in'"></app-feather-icons>
        <span>{{ 'account.logout' | translate }}</span>
    </li>
</ul>