import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { ApplicationConfigService } from '../../../configs/application-config.service';
import { GenericService } from '../generic/generic.service';
import { IBrandTemplate } from '../../../shared/models/brand-template/brand-template.model';
import { IBrandTemplateService } from '../../../shared/interface/brand-template/brand-template-interface.service';
import { IConnectedMedia } from 'src/app/shared/models/connected-media/connected-media.model';
import { Observable, Subject } from 'rxjs';
import { IPagingFilter } from '../../../shared/models/helper/paging-filter.model';

export type EntityResponseType = HttpResponse<any>;

@Injectable({
  providedIn: 'root',
})
export class BrandTemplateService extends GenericService<IBrandTemplate> implements IBrandTemplateService {
  constructor(
    httpClient: HttpClient,
    applicationConfigService: ApplicationConfigService
  ) {
    super(httpClient, applicationConfigService, "brand-template");
  }

  add(name: string, brandLogo: string, active: any, brandDomainId: any, htmlFile: File, cssFile: File): Observable<HttpResponse<any>> {
    const params = new HttpParams()
      .set('name', name)
      .set('brandLogo', brandLogo)
      .set('active', active.toString())
      .set('brandDomainId', brandDomainId);

    const formData = new FormData();
    formData.append('html', htmlFile, htmlFile.name);
    formData.append('css', cssFile, cssFile.name);
    return this.httpClient.post<any>(`${this.resourceUrl}`, formData, { params: params, observe: 'response' });
  }

  getTemplateByBrandDomain(brandDomainId: number) {
    return this.httpClient.get<IBrandTemplate[]>(`${this.resourceUrl}/brand-domain/${brandDomainId}`);
  }

  getConnectedMedias(entityId: number) {
    return this.httpClient.get<IConnectedMedia[]>(`${this.resourceUrl}/${entityId}/connected-medias`);
  }

  getTemplateList(filters: IPagingFilter[]): Observable<HttpResponse<IBrandTemplate[]>> {
    let httpParams = new HttpParams();

    filters.forEach(filter => {
      if (filter.value) {
        httpParams = httpParams.append(filter.field, filter.value);
      }
    });

    return this.httpClient.get<IBrandTemplate[]>(`${this.resourceUrl}`, {
      params: httpParams,
      observe: 'response',
    });
  }
}