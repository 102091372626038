import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { ApplicationConfigService } from '../../../configs/application-config.service';
import { GenericService } from '../generic/generic.service';
import { IBrandService } from '../../../shared/interface/brand/brand-interface.service';
import { IBrand } from '../../../shared/models/brand/brand.model';

export type EntityResponseType = HttpResponse<any>;

@Injectable({
  providedIn: 'root',
})
export class BrandService extends GenericService<IBrand> implements IBrandService {
    constructor(
      httpClient: HttpClient,
      applicationConfigService: ApplicationConfigService
  ) {
        super(httpClient,applicationConfigService,"brand");
    }
}