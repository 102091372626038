import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-error401',
  templateUrl: './error401.component.html',
  styleUrls: ['./error401.component.scss']
})
export class Error401Component implements OnInit {

  constructor(public router: Router) { }

  ngOnInit(): void {
  }

  GoBack(){
    if(localStorage.getItem("role").includes("ADMIN_POCHET") || localStorage.getItem("role").includes("USER_POCHET")) {
      this.router.navigate(["/dashboard/pochet/flask/collection"]);
    }
    else{
        this.router.navigate(["/dashboard/metrics"]);   
    }  
  }
}
