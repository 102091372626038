import { Injectable, InjectionToken } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable, map } from 'rxjs';
import { ApplicationConfigService } from '../../../configs/application-config.service';
import { IPagingFilter } from '../../../shared/models/helper/paging-filter.model';
import { IGenericService } from '../../../shared/interface/generic/generic-interface.service';

export type EntityResponseType = HttpResponse<any>;

@Injectable({
  providedIn: 'root',
})
export class GenericService<T> implements IGenericService<T>{
    public resourceUrl: string;
    constructor(
        public httpClient: HttpClient,
        private applicationConfigService: ApplicationConfigService,        
        public url: string
    ) {
        this.resourceUrl = this.applicationConfigService.getEndpointFor().concat('api/'+ url);
    }

    hasPermission(permission: string) : boolean {
        if(localStorage.getItem('role').includes("SUPERADMIN"))
        {
            return true;
        }
        if(localStorage.getItem('permissions').includes(permission))
        {
            return true;
        }
        return false;
    }

    create(entity: T): Observable<HttpResponse<T>> {
        const headers = { 'Content-Type': 'application/json' };
        return this.httpClient
            .post<T>(this.resourceUrl, entity, {
                headers: headers,
                observe: 'response',
            })
            .pipe(
                map((response: HttpResponse<T>) => {
                return response;
            })
        );
    }

    update(entity: T, entityId: any): Observable<HttpResponse<T>> {
        const headers = { 'Content-Type': 'application/json' };
        return this.httpClient
            .put<T>(`${this.resourceUrl}/${entityId}`, entity, {
                headers: headers,
                observe: 'response',
            })
            .pipe(
                map((response: HttpResponse<T>) => {
                return response;
            })
        );
    }

    delete(entityId: number): Observable<HttpResponse<{}>> {
		return this.httpClient.delete(`${this.resourceUrl}/${entityId}`, { observe: 'response' });
	}

    getDetail(entityId: number){
        return this.httpClient.get<T>(`${this.resourceUrl}/${entityId}`);
    }

    getDetailByLocale(entityId: number, i18n: string){
        return this.httpClient.get<T>(`${this.resourceUrl}/${entityId}/${i18n}`);
    }

    getDetailStr(entityId: string){
        return this.httpClient.get<T>(`${this.resourceUrl}/${entityId}`);
    }
    
    getList():Observable<T[]>{
        return this.httpClient.get<T[]>((`${this.resourceUrl}`));
    }

    getPaging(filters: IPagingFilter[]): Observable<T[]> {
        let filterParams: HttpParams = new HttpParams();
        filters.forEach(element => {
                filterParams = filterParams.set(element.field, element.value);
        });
		return this.httpClient.get<T[]>(`${this.resourceUrl}/get-paging`, {
			params: filterParams,
		});
	} 

    getEntities(filters: IPagingFilter[]): Observable<T[]> {
        let filterParams: HttpParams = new HttpParams();
        filters.forEach(element => {
                filterParams = filterParams.set(element.field, element.value);
        });
		return this.httpClient.get<T[]>(`${this.resourceUrl}`, {
			params: filterParams,
		});
	}

    getPagingWithBrandDomainIds(filters: IPagingFilter[], ids: number[]): Observable<T[]> {
        let filterParams: HttpParams = new HttpParams();
        filters.forEach(element => {
                filterParams = filterParams.set(element.field, element.value);
        });
        let url = `${this.resourceUrl}?`;
        for (let id of ids) {
            url += `brandDomainIds=${id}&`;
        }
      return this.httpClient.get<T[]>(url, {
        params: filterParams,
      });
	} 
}