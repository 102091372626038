<div class="container-fluid p-0">
    <div class="row">
        <div class="col-12">
            <div class="login-card">
                <div>
                    <div class="login-main">
                        <a class="logo" routerLink="/">
                            SCALABEL
                        </a>
                        <form class="theme-form" [formGroup]="resetForm">
                            <h5>{{ 'login.forgetTitle' | translate }}</h5>
                            <div class="form-group">
                                <label class="col-form-label">{{ 'login.email' | translate }}</label>
                                <input class="form-control" formControlName="email" type="email" required="" placeholder="app@yourmail.com" />
                            </div>
                            <div class="form-group mb-0">
                                <button 
                                    (click)="resetPasswordRequest()"
                                    class="btn btn-primary d-block w-100" 
                                    type="button">
                                    
                                    <i *ngIf="loading" class="fa fa-spin fa-spinner me-2"></i>
                                    <span *ngIf="!loading">{{ 'common.send' | translate }}</span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
