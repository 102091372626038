import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { ApplicationConfigService } from '../../../configs/application-config.service';
import { GenericService } from '../generic/generic.service';
import { IUserApplication } from '../../../shared/models/user-application/user-application.model';
import { IUserApplicationService } from '../../../shared/interface/user-application/user-application-interface.service';
import { Observable } from 'rxjs';
import { IBrandDomain } from '../../../shared/models/brand-domain/brand-domain.model';
import { IRole } from 'src/app/shared/models/application-user/role.model';

export type EntityResponseType = HttpResponse<any>;

@Injectable({
  providedIn: 'root',
})
export class UserApplicationService extends GenericService<IUserApplication> implements IUserApplicationService {
    constructor(
      httpClient: HttpClient,
      applicationConfigService: ApplicationConfigService
  ) {
        super(httpClient,applicationConfigService,"user-application");
    }

    getBrandsDomain(): Observable<IBrandDomain[]> {
        return this.httpClient.get<IBrandDomain[]>(`${this.resourceUrl}/get-domains`);
    }

    getDomainsRoles(): Observable<IRole[]> {
        return this.httpClient.get<IRole[]>(`${this.resourceUrl}/domains-roles`);
    }
}