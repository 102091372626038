import { Component, Input } from '@angular/core';
import { LandingUrlService } from '../../../core/services/landing-url.service';

@Component({
    selector: 'app-breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent {
    @Input() title: string;
    @Input() items: any[];
    @Input() active_item: string;

    constructor(
        private landingUrlSvc: LandingUrlService
    ){}

    getRedirectUrl(){
        return this.landingUrlSvc.getRedirectUrl();
    }
}
