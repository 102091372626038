<div class="container-fluid p-0">
    <div class="row">
        <div class="col-12">
            <div class="login-card">
                <div>
                    <div class="login-main">
                        <a class="logo" routerLink="/">
                            SCALABEL
                        </a>
                        <form class="theme-form" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                            <h5>{{ 'login.title' | translate }}</h5>
                            <div class="form-group">
                                <label class="col-form-label">{{ 'login.email' | translate }}</label>
                                <input class="form-control" type="email" required="" placeholder="app@yourmail.com"
                                    formControlName="email" />
                            </div>
                            <div class="form-group">
                                <label class="col-form-label">{{ 'login.password' | translate }}</label>
                                <input class="form-control" [type]="show ? 'text' : 'password'"
                                    formControlName="password" required="" placeholder="*********" />
                                <div class="show-hide" (click)="showPassword()" *ngIf="!show">
                                    <i class="icofont icofont-eye-blocked"></i>
                                </div>
                                <div class="show-hide" (click)="showPassword()" *ngIf="show">
                                    <i class="icofont icofont-eye"></i>
                                </div>
                            </div>
                            <div class="form-group mb-0">
                                <div class="checkbox p-0">
                                    <input id="checkbox1" type="checkbox">
                                    <label for="checkbox1">{{ 'login.rememberPassword' | translate }}</label>
                                </div>
                                <a [routerLink]="'/forget-password'" class="link text-end">{{ 'login.forgotPassword' |
                                    translate }}</a>
                                <button class="btn btn-primary d-block w-100" [disabled]="!loginForm.valid"
                                    type="submit">
                                    <i *ngIf="loading" class="fa fa-spin fa-spinner me-2"></i>
                                    <span *ngIf="!loading">{{ 'login.signIn' | translate }}</span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>