import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root',
})
export class LandingUrlService {
    private redirectUrl: string = '';

    setRedirectUrl(url: string): void {
        this.redirectUrl = url;
    }

    getRedirectUrl(): string {
        return this.redirectUrl;
    }
}
