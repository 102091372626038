import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { ApplicationConfigService } from '../../../configs/application-config.service';
import { GenericService } from '../generic/generic.service';
import { IFlask } from '../../../shared/models/flask/flask.model';
import { IFlaskService } from '../../../shared/interface/flask/flask-interface.service';

export type EntityResponseType = HttpResponse<any>;

@Injectable({
    providedIn: 'root',
})
export class FlaskService extends GenericService<IFlask> implements IFlaskService {
    constructor(
        httpClient: HttpClient,
        applicationConfigService: ApplicationConfigService
    ){
        super(httpClient,applicationConfigService,"flask");
    }
}