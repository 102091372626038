import { Component, Inject, OnInit } from '@angular/core';
import { IGenericService } from '../../../shared/interface/generic/generic-interface.service';
import { HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-form',
    templateUrl: './form.component.html',
    styleUrls: ['./form.component.scss']
})
export class FormComponent<T> implements OnInit {
    entity: T;
    entityId: any;
    loading: boolean = false;
    message: {
        success: string;
        error: string;
    } = { success: '', error: '' };
    redirectUrl: string[] = [];

    constructor(
        @Inject('GENERIC_SERVICE') public genericService: IGenericService<T>,
        public router: Router
    ){}

    ngOnInit() {
    }

    create(){
        this.subscribeToSaveResponse(this.genericService.create(this.entity), this.message, this.redirectUrl);
    }

    update(){
        this.subscribeToSaveResponse(this.genericService.update(this.entity, this.entityId), this.message, this.redirectUrl);
    }

    onSaveError(message): void {
        Swal.fire({
            icon: 'error',
            title: 'Error',
            text: message.error
        });
    }

    onSaveSuccess(message): void {
        Swal.fire({
            icon: 'success',
            title: 'Success',
            text: message.success,
            showConfirmButton: false,
            width: 400,
            timer: 2000
        });
    }

    subscribeToSaveResponse(result: Observable<HttpResponse<T>>, message: {}, redirectUrl :string[]): void {
        result.subscribe({
            next: (response) => {
                this.onSaveSuccess(message);
                this.router.navigate(redirectUrl); 
            },
            error: (error) => {
                this.onSaveError(message);
            },
        });
    }
}

