import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { ApplicationConfigService } from '../../../configs/application-config.service';
import { GenericService } from '../generic/generic.service';
import { IClientConfiguration } from '../../../shared/models/client-configuration/client-configuration.model';
import { IClientConfigurationService } from '../../../shared/interface/client-configuration/client-configuration-interface.service';

export type EntityResponseType = HttpResponse<any>;

@Injectable({
    providedIn: 'root',
})
export class ClientConfigurationService extends GenericService<IClientConfiguration> implements IClientConfigurationService {
    constructor(
        httpClient: HttpClient,
        applicationConfigService: ApplicationConfigService
    ) {
        super(httpClient,applicationConfigService,"client-configuration");
    }
}
