import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-paging-selector-footer',
  templateUrl: './paging-selector-footer.component.html',
  styleUrls: ['./paging-selector-footer.component.scss']
})
export class PagingSelectorFooterComponent {
    @Input() currentPage;
    @Input() listingTitle;
    @Input() numbersArray;
    @Input() nbPages;
    @Input() pageSize;
    @Input() totalElementCount;
    @Output() pageChange = new EventEmitter<number>();

    onChangePage(newPage: number){
        this.pageChange.emit(newPage);
    }
}
