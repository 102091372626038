import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { ApplicationConfigService } from '../../../configs/application-config.service';
import { GenericService } from '../generic/generic.service';
import { IPermissionService } from '../../../shared/interface/permission/permission-interface.service';
import { IPermission } from '../../../shared/models/permission/permission.model';
import { IPermissionList } from 'src/app/shared/models/permission/permission-list.model';
import { Observable } from 'rxjs';

export type EntityResponseType = HttpResponse<any>;

@Injectable({
  providedIn: 'root',
})
export class PermissionService extends GenericService<IPermission> implements IPermissionService {
    constructor(
      httpClient: HttpClient,
      applicationConfigService: ApplicationConfigService
  ) {
        super(httpClient,applicationConfigService,"permission");
    }

    getListedPermissions(): Observable<IPermissionList[]> {
        let url = `${this.resourceUrl}/get-all-permissions`;
        return this.httpClient.get<IPermissionList[]>(url);
    }
}