import { Directive, ElementRef, AfterViewInit } from '@angular/core';

@Directive({
    selector: '[adjustImageHeight]'
})
export class AdjustImageHeightDirective implements AfterViewInit {

    constructor(private el: ElementRef) { }

    ngAfterViewInit() {
        this.adjustHeight();
    }

    private adjustHeight() {
        const element = this.el.nativeElement;
        const secondDiv = document.querySelector('.col-xxl-8 box-col-6') as HTMLElement;
        const secondDivHeight = secondDiv.offsetHeight;
        element.style.height = secondDivHeight + 'px';
    }
}
