import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { ApplicationConfigService } from '../../../configs/application-config.service';
import { GenericService } from '../generic/generic.service';
import { IScanHistory } from '../../../shared/models/scan-history/scan-history.model';
import { IScanHistoryService } from '../../../shared/interface/scan-history/scan-history-interface.service';
import { Observable } from 'rxjs';

export type EntityResponseType = HttpResponse<any>;

@Injectable({
  providedIn: 'root',
})
export class ScanHistoryService extends GenericService<IScanHistory> implements IScanHistoryService {
    constructor(
      httpClient: HttpClient,
      applicationConfigService: ApplicationConfigService
  ) {
        super(httpClient,applicationConfigService,"scan-history");
    }

	getCountByScanCountry(startDate: string, endDate: string): Observable<any[]> {
        if(this.hasPermission("ScanHistory.CountByCountry"))
		{
			let filterParams: HttpParams = new HttpParams();
			if (startDate){
				filterParams = filterParams.set('StartDate', startDate);
			}
			if (endDate){
				filterParams = filterParams.set('EndDate', endDate);
			}
			return this.httpClient.get<any[]>(`${this.resourceUrl}/count-by-country`, {
				params: filterParams,
			});;
		}
		else
		{
			return new Observable<any[]>
		}
	}

    getCountByScanDate(startDate: string, endDate: string): Observable<any[]> {
		if(this.hasPermission("ScanHistory.CountByDate"))
		{
			let filterParams: HttpParams = new HttpParams();
			if (startDate){
				filterParams = filterParams.set('StartDate', startDate);
			}
			if (endDate){
				filterParams = filterParams.set('EndDate', endDate);
			}
			return this.httpClient.get<any[]>(`${this.resourceUrl}/count-by-scan-date`, {
				params: filterParams,
			});;
		}
		else
		{
			return new Observable<any[]>
		}
	}
}