import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpParams  } from '@angular/common/http';
import { ApplicationConfigService } from '../../../configs/application-config.service';
import { GenericService } from '../generic/generic.service';
import { IAnalysis } from '../../../shared/models/analysis/analysis.model';
import { IAnalysisService } from '../../../shared/interface/analysis/analysis-interface.service';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { IPagingFilter } from 'src/app/shared/models/helper/paging-filter.model';

export type EntityResponseType = HttpResponse<any>;

@Injectable({
    providedIn: 'root',
})
export class AnalysisService extends GenericService<IAnalysis> implements IAnalysisService {
    private defaultFilterSubject = new BehaviorSubject<any>(null);
    defaultFilter$ = this.defaultFilterSubject.asObservable();

    constructor(
        httpClient: HttpClient,
        applicationConfigService: ApplicationConfigService
    ){
        super(httpClient,applicationConfigService,"analysis");
    }

    getDefaultFilter(): any {
        return this.defaultFilterSubject.value;
    }
    
    getFlaskFromAnalysis(analysisId: number, filters: IPagingFilter[]): Observable<any> {
        let filterParams: HttpParams = new HttpParams();
        filters.forEach(element => {
                filterParams = filterParams.set(element.field, element.value);
        });
		return this.httpClient.get<any>(`${this.resourceUrl}/flasks/${analysisId}`, {
			params: filterParams,
		});
	} 

    getDefaultList(analysisId: number): Observable<any> {
        return this.httpClient.get<any>(`${this.resourceUrl}/filter/${analysisId}`);
    }


    getFromFilters(analysisId: number, filters: any, startDate: string, endDate: string): Observable<any> {
        const filtersString = JSON.stringify(filters);
        let params = new HttpParams();
        params = params.set('filter', filtersString);
        if (startDate){
            params = params.set('startdate', startDate);
        }
        if (endDate){
            params = params.set('enddate', endDate);
        }
        return this.httpClient.get<any>(`${this.resourceUrl}/filter/${analysisId}`, { params });
    }

    setDefaultFilter(filter: any) {
        this.defaultFilterSubject.next(filter);
    }

    updateStatus(statusId: number, analysisId: number): Observable<any> {
        const headers = { 'Content-Type': 'application/json' };
        return this.httpClient
            .put<any>(`${this.resourceUrl}/status/${analysisId}/?status=${statusId}`,{
                headers: headers,
                observe: 'response',
            })
            .pipe(
                map((response: HttpResponse<any>) => {
                return response;
            })
        );
    }
}