import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as data  from '../../data/paging/paging-selector';

@Component({
    selector: 'app-paging-selector',
    templateUrl: './paging-selector.component.html',
    styleUrls: ['./paging-selector.component.scss']
})
export class PagingSelectorComponent {
    @Input() listingTitle: string;
    @Output() pageSize = new EventEmitter<number>();
    public pageSizeOptions   =  data.paginate;
    public selectedPageSize: number = this.pageSizeOptions[0];

    constructor(
        private translate: TranslateService
    ){}
    
    onSizeChange() {
        this.pageSize.emit(this.selectedPageSize);
    }
}
