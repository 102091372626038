<div class="translate_wrapper" [class.active]="navServices.language">
    <div class="current_lang">
        <div class="lang">
            <i class="flag-icon flag-icon-{{selectedLanguage.icon}}"></i>
            <span class="lang-txt">{{selectedLanguage.label}}</span>
        </div>
    </div>
    <div class="more_lang" [class.active]="navServices.language">
        <div class="lang selected" data-value="en" (click)="changeLanguage(lang)" *ngFor="let lang of languages">
            <i class="flag-icon flag-icon-{{lang.icon}}"></i>
            <span class="lang-txt">{{lang.language}}
                <span *ngIf="lang.type">({{lang.type}})</span>
            </span>
        </div>
    </div>
</div>