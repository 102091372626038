import { Directive, Input, TemplateRef, ViewContainerRef, OnInit } from '@angular/core';
 
@Directive({
    selector: '[hasGeoloc]'
})
export class GeolocDirective implements OnInit {
    private isHidden = true;

    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef
    ) {}

    ngOnInit() {
        this.updateView();
    }

    private updateView() {
        const hasGeolocAuthorize = localStorage.getItem("scanGeolocAuthorize") === 'true';
        if (hasGeolocAuthorize) {
            if (this.isHidden) {
                this.viewContainer.createEmbeddedView(this.templateRef);
                this.isHidden = false;
            }
        } else {
            this.isHidden = true;
            this.viewContainer.clear();
        }
    }
}
  