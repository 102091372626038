import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AccountService } from 'src/app/core/services/account/account.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss']
})
export class ForgetPasswordComponent{

    public resetForm: FormGroup;
    public loading: boolean = false;

    constructor(
        private fb: FormBuilder, 
        private translate: TranslateService,
        private accountService: AccountService,
        public router: Router,
    ){
      this.resetForm = this.fb.group({
      email: [""],
    });
  }
    
  resetPasswordRequest() {
    if(this.loading == false) {
      this.loading = true;
        this.accountService.resetPasswordRequest(this.resetForm.controls["email"].value).subscribe((res) => {
          Swal.fire('Success', this.translate.instant('forgetPassword.requestTaken'), 'success');
          this.loading = false;
          this.router.navigate(['/login']);
        });
      }
    }
}
