import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-circular-indicator',
    templateUrl: './circular-indicator.component.html',
    styleUrls: ['./circular-indicator.component.scss']
})
export class CircularIndicatorComponent {
    @Input() rating: number;
    ratingButtons: any[];

    ngOnInit(): void {
        this.calculateRating();
    }

    calculateRating() {
        const roundedRating = Math.floor(this.rating * 2) / 2;
        const full          = Math.floor(roundedRating);
        const half          = (roundedRating % 1) !== 0;
        this.ratingButtons = [];
        for (let i = 0; i < full; i++) {
            this.ratingButtons.push({ color: 'black' });
        }
        if (half) {
            this.ratingButtons.push({ color: 'half-black-half-white' });
        }
        const remainingButtons = 5 - this.ratingButtons.length;
        for (let i = 0; i < remainingButtons; i++) {
            this.ratingButtons.push({ color: 'white' });
        }
    }
}
