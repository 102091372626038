import {
  Directive,
  Input,
  TemplateRef,
  ViewContainerRef,
  ElementRef,
  OnInit,
  Attribute
} from '@angular/core';
import { UserService } from '../../core/services/user.service';

@Directive({
  selector: '[hasNotPermission]'
})
export class HasNotPermissionDirective implements OnInit {
  private permission : string;
  private logicalOp = 'AND';
  private isHidden = true;

  constructor(
    private element: ElementRef,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private userService: UserService
  ) {

  }

  ngOnInit() {
      this.updateView();
  }

  @Input()
  set hasPermission(val) {
    this.permission = val;
    this.updateView();
  }

  @Input()
  set hasPermissionOp(permop) {
    this.logicalOp = permop;
    this.updateView();
  }

  private updateView() {
    if (this.checkPermission()) {
      if(this.isHidden) {
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.isHidden = false;
      }
    } else {
      this.isHidden = true;
      this.viewContainer.clear();
    }
  }

  private checkPermission() {
    let hasPermission = true;
    if(localStorage.getItem("role")?.includes("SUPERADMIN")){
      hasPermission = false;
      return hasPermission;
    }

    if (localStorage.getItem("permissions")) {
        this.permission = this.permission + "";
        var splitted = this.permission.split(","); 
        splitted.forEach(perm => {
          const permissionFound = localStorage.getItem("permissions")?.includes(perm);
          if (permissionFound) {
            hasPermission = false;
          }
        });

        
    }
    return hasPermission;
  }
}
