import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { DashboardRoutingModule } from './modules/dashboard/dashboard-routing.module';
import { PublicRoutingModule } from './modules/public/public-routing.module';

@NgModule({
    imports: [
        RouterModule.forRoot([]),
        DashboardRoutingModule,
        PublicRoutingModule,  
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {}
