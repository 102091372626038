import { Component, ViewEncapsulation, HostListener, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Menu, NavService } from '../../../core/services/nav.service';
import { LandingUrlService } from '../../../core/services/landing-url.service';
import { LayoutService } from '../../../core/services/layout.service';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class SidebarComponent implements OnInit{
    public iconSidebar;
    public menuItems: Menu[];
    // For Horizontal Menu
    public margin: any = 0;
    public width: any = window.innerWidth;
    public leftArrowNone: boolean = true;
    public redirectUrl: string;
    public rightArrowNone: boolean = false;

    constructor(
        private router: Router, 
        public navServices: NavService,
        private landingUrlSvc: LandingUrlService,
        public layout: LayoutService
    ) {
        this.navServices.items.subscribe(menuItems => {
            this.navServices.items.subscribe(menuItems => {
                this.menuItems = menuItems.filter(item => this.navServices.isMenuItemVisible(item));
                this.menuItems.forEach(item => {
                    if (item.children) {
                        item.children = item.children.filter(subItem => this.navServices.isMenuItemVisible(subItem));
                    }
                });
                this.router.events.subscribe((event) => {
                    if (event instanceof NavigationEnd) {
                        this.menuItems.filter(items => {
                            if (items.path === event.url) {
                                this.setNavActive(items);
                            }
                            if (!items.children) { return false; }
                            items.children.filter(subItems => {
                                if (subItems.path && event.url.includes(subItems.path)) {
                                    this.setNavActive(subItems);
                                }
                                if (!subItems.children) { return false; }
                                subItems.children.filter(subSubItems => {
                                    if (subSubItems.path === event.url) {
                                        this.setNavActive(subSubItems);
                                    }
                                });
                            });
                        });
                    }
                });
            });
        });
    }

    ngOnInit(){
        this.redirectUrl = localStorage.getItem('redirectUrl');
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.width = event.target.innerWidth - 500;
    }

    sidebarToggle() {
        this.navServices.collapseSidebar = !this.navServices.collapseSidebar;
    }

    setNavActive(item) {
    this.menuItems.filter(menuItem => {
        if (menuItem !== item) {
            menuItem.active = false;
        }
        if (menuItem.children && menuItem.children.includes(item)) {
            menuItem.active = true;
        }
        if (menuItem.children) {
            menuItem.children.filter(submenuItems => {
                if (submenuItems.children && submenuItems.children.includes(item)) {
                    menuItem.active = true;
                    submenuItems.active = true;
                }
            });
        }
    });
    }

    toggletNavActive(item) {
        if (!item.active) {
            this.menuItems.forEach(a => {
                if (this.menuItems.includes(item)) {
                    a.active = false;
                }
                if (!a.children) { return false; }
                a.children.forEach(b => {
                    if (a.children.includes(item)) {
                    b.active = false;
                    }
                });
            });
        }
        item.active = !item.active;
    }

    scrollToLeft() {
        if (this.margin >= -this.width) {
            this.margin = 0;
            this.leftArrowNone = true;
            this.rightArrowNone = false;
        } else {
            this.margin += this.width;
            this.rightArrowNone = false;
        }
    }

    scrollToRight() {
        if (this.margin <= -3051) {
            this.margin = -3464;
            this.leftArrowNone = false;
            this.rightArrowNone = true;
        } else {
            this.margin += -this.width;
            this.leftArrowNone = false;
        }
    }
}
