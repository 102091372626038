import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { ApplicationConfigService } from '../../../configs/application-config.service';
import { GenericService } from '../generic/generic.service';
import { IProduct } from '../../../shared/models/product/product.model';
import { IProductService } from '../../../shared/interface/product/product-interface.service';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';

export type EntityResponseType = HttpResponse<any>;
@Injectable({
  providedIn: 'root',
})
export class ProductService extends GenericService<IProduct> implements IProductService {

    datasProduct: any;

    constructor(
        httpClient: HttpClient,
        applicationConfigService: ApplicationConfigService
    ){
        super(httpClient,applicationConfigService,"product");
    }

    createList(entities: IProduct[]): Observable<HttpResponse<IProduct[]>> {
      const headers = { 'Content-Type': 'application/json' };
      return this.httpClient
          .post<IProduct[]>(`${this.resourceUrl}/product-list`, entities, {
              headers: headers,
              observe: 'response',
          })
          .pipe(
              map((response: HttpResponse<IProduct[]>) => {
              return response;
          })
      );
    }

    createDemoProduct(digitalId?: string): Observable<HttpResponse<any>> {
        const headers = { 'Content-Type': 'application/json' };
        let param: HttpParams = new HttpParams();
        param = param.set("digitalId", digitalId);
        return this.httpClient
            .post<any>(`${this.resourceUrl}/demo`,null,{params: param})
            .pipe(
                map((response: HttpResponse<any>) => {
                return response;
            })
        );
    }
    
    getDetailFromDigitalId(digitalId: number){
        return this.httpClient.get<IProduct>(`${this.resourceUrl}/get-from-digital-id/${digitalId}`);
    }
    
    getDetailFromSku(sku: string){
        return this.httpClient.get<IProduct>(`${this.resourceUrl}/get-from-sku/${sku}`);
    }
    
    getProductLocales(id: number){
        return this.httpClient.get<IProduct>(`${this.resourceUrl}/locales/${id}`);
    }

    getDatas(){
        return this.datasProduct;
    }

    setDatas(datasProduct: any){
        this.datasProduct = datasProduct;
    }
}