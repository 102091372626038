import { Component, Inject, OnInit } from '@angular/core';
import { IGenericService } from '../../../shared/interface/generic/generic-interface.service'
import { IPagingFilter } from '../../models/helper/paging-filter.model';
import { IBrandDomain } from '../../models/brand-domain/brand-domain.model';
import { IUserApplicationService } from '../../interface/user-application/user-application-interface.service';

@Component({
    selector: 'app-paging',
    templateUrl: './paging.component.html',
    styleUrls: ['./paging.component.scss']
})
export class PagingComponent<T> implements OnInit {
    currentPage: number = 1;
    pageSize: number = 10;
    nbPages: number = 0;
    nbTotalEntities: number;
    numbersArray: number[];
    filters: IPagingFilter[] = [];
    newFilters: IPagingFilter[] = [];
    isAlreadyFiltered: boolean = false;
    isFiltered: boolean = false;
    loading: boolean = false;
    nbEntityFiltered: number;
    entityList: T[] = [];
    limiteInf: number = 1;
    limiteSup: number = 10;

    brandDomains: IBrandDomain[] = [];
    selectedBrandDomainIds: number[] = [];
    selectedBrandDomains: IBrandDomain[] = [];
    genericFilters: IPagingFilter[] = [];

    constructor(
        @Inject('GENERIC_SERVICE') public genericService: IGenericService<T>,
        @Inject('USER_APPLICATION_SERVICE') public userApplicationService?: IUserApplicationService
    ) {
        if (userApplicationService != null) {
            this.getBrandDomainList();
        }
    }

    ngOnInit() {
    }

    putString(value: string): string {
        return value != undefined ? value : ""
    }

    getPaging() {
        this.loading = true;
        this.mergeFilters();
        this.genericService.getPaging(this.genericFilters).subscribe((res) => {
            this.entityList = res['pagingList'];
            this.nbTotalEntities = res['entityCount'];
            const nbPageTmp = this.nbTotalEntities / this.pageSize;
            this.nbPages = Number.isInteger(nbPageTmp) ? nbPageTmp : Math.floor(nbPageTmp) + 1;
            this.numbersArray = this.buildNumbersArray(this.nbPages);
            this.limiteSup = Math.min(this.nbTotalEntities, this.limiteInf + this.pageSize - 1);
        });
        this.loading = false;
    }

    getPagingWithBrandDomainIds() {
        this.loading = true;
        this.mergeFilters();
        this.genericService.getPagingWithBrandDomainIds(this.genericFilters, this.selectedBrandDomainIds).subscribe((res) => {
            this.entityList = res['pagingList'];
            this.nbTotalEntities = res['entityCount'];
            const nbPageTmp = this.nbTotalEntities / this.pageSize;
            this.nbPages = Number.isInteger(nbPageTmp) ? nbPageTmp : Math.floor(nbPageTmp) + 1;
            this.numbersArray = this.buildNumbersArray(this.nbPages);
            this.limiteSup = Math.min(this.nbTotalEntities, this.limiteInf + this.pageSize - 1);
        });
        this.loading = false;
    }

    brandDomainsChange() {
        this.selectedBrandDomainIds = this.selectedBrandDomains.map(({ id }) => id);
        this.getPagingWithBrandDomainIds()
    }

    getBrandDomainList() {
        this.userApplicationService.getBrandsDomain().subscribe(brandDomains => {
            this.brandDomains = brandDomains;
        });
    }

    buildNumbersArray(nbPages: number): number[] {
        let result: number[] = [];
        for (let i = Math.max(this.currentPage - 2, 1); i <= Math.min(this.currentPage + 2, nbPages); i++) {
            result.push(i);
        }
        return result;
    }

    onPageChange(newPage: number) {
        this.currentPage = newPage;
        this.limiteInf = (newPage - 1) * this.pageSize + 1;
        this.limiteSup = this.limiteInf + (this.currentPage < this.nbPages ? + this.pageSize : this.nbTotalEntities % this.pageSize) - 1;
        this.numbersArray = this.buildNumbersArray(this.nbPages);
        this.generateElementsSize(this.currentPage, this.pageSize);
        this.getPaging();
    }

    generateFilters(newFilters: IPagingFilter[]) {
        this.newFilters = newFilters;
    }

    generateElementsSize(page: number, pageSize: number) {
        this.filters = [
            {
                field: 'page',
                value: page.toString(),
            },
            {
                field: 'pageSize',
                value: pageSize.toString(),
            }
        ];
    }

    mergeFilters() {
        this.genericFilters = this.filters.concat(this.newFilters);
    }

    onRemoveFilters() {
        this.isFiltered = false;
    }

    onSizeChange(pageSize: number) {
        this.pageSize = pageSize;
        this.limiteInf = 1;
        this.limiteSup = this.limiteInf + this.pageSize;
        this.currentPage = 1;
        this.generateElementsSize(this.currentPage, this.pageSize);
        this.getPaging();
    }

    trackById(index: number, obj: any): number {
        return obj.id;
    }
}
