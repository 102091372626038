import { Pipe, PipeTransform, Inject, LOCALE_ID } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
    name: 'manageDate'
})
export class ManageDatePipe implements PipeTransform {

    constructor(@Inject(LOCALE_ID) private locale: string) {}

    transform(value: any): string {
        if (!value) {
            return '';
        }
        const dateObject = (value instanceof Date) ? value : new Date(value);
        if (isNaN(dateObject.getTime())) {
            return '';
        }
        const datePipe = new DatePipe(this.locale);
        const transformedDate = datePipe.transform(dateObject, 'dd/MM/yyyy HH:mm:ss');
        return transformedDate;
    }
}
