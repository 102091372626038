import { FormControl, AbstractControl, FormGroupDirective } from '@angular/forms';
import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-error-field',
    templateUrl: './error-field.component.html',
    styleUrls: ['./error-field.component.scss'],
    host: { style: 'color: red;' },
})
export class ErrorFieldComponent {
    @Input() errorMsg: string;
    @Input() displayError: boolean;
}