import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { ApplicationConfigService } from '../../../configs/application-config.service';
import { GenericService } from '../generic/generic.service';
import { IScanHistory } from '../../../shared/models/scan-history/scan-history.model';
import { IScanHistoryService } from '../../../shared/interface/scan-history/scan-history-interface.service';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

export type EntityResponseType = HttpResponse<any>;

@Injectable({
  providedIn: 'root',
})
export class ScanHistoryService extends GenericService<IScanHistory> implements IScanHistoryService {

  constructor(
    public translate: TranslateService,
    public httpClient: HttpClient,
    applicationConfigService: ApplicationConfigService
  ) {
    super(httpClient, applicationConfigService, 'scan-history');
  }

  /**
   * Get count by date
   * 
   * @param brandDomainIds 
   * @param startDate 
   * @param endDate 
   * 
   * @returns Observable<any[]>
   */
  getCountByDate(brandDomainIds: number[], startDate: string, endDate: string): Observable<any[]> {
    return this.getCountData('count-by-date', 'CountByDate', brandDomainIds, startDate, endDate);
  }

  /**
   * Get count by country
   * 
   * @param brandDomainIds 
   * @param startDate 
   * @param endDate 
   * 
   * @returns Observable<any[]>
   */
  getCountByCountry(brandDomainIds: number[], startDate: string, endDate: string): Observable<any[]> {
    return this.getCountData('count-by-country', 'CountByCountry', brandDomainIds, startDate, endDate);
  }

  /**
   * Get count by product
   * 
   * @param brandDomainIds 
   * @param startDate 
   * @param endDate 
   * 
   * @returns Observable<any[]>
   */
  getCountByProduct(brandDomainIds: number[], startDate: string, endDate: string): Observable<any[]> {
    return this.getCountData('count-by-product', 'CountByProduct', brandDomainIds, startDate, endDate);
  }

  /**
   * Helper to create HttpParams for filtering based on dates.
   * 
   * @param brandDomainIds 
   * @param startDate 
   * @param endDate
   * 
   * @return Object  
   */
  private createFilterParams(brandDomainIds: number[], startDate?: string, endDate?: string): HttpParams {
    let params = new HttpParams();
    if (brandDomainIds?.length > 0) {
      brandDomainIds
        .filter(id => id !== null && id !== undefined)
        .forEach(id => {
          params = params.append('brandDomainIds', id.toString());
        });
    }
    startDate && (params = params.set('StartDate', startDate));
    endDate && (params = params.set('EndDate', endDate));
    return params;
  }

  /**
   * Get count for scans
   * 
   * @param endpoint 
   * @param permission 
   * @param brandDomainIds 
   * @param startDate 
   * @param endDate 
   * 
   * @returns Observable<any[]>
   */
  private getCountData(endpoint: string, permission: string, brandDomainIds: number[], startDate: string, endDate: string): Observable<any[]> {
    if (this.hasPermission(`ScanHistory.${permission}`)) {
      const params = this.createFilterParams(brandDomainIds, startDate, endDate);
      return this.httpClient.get<any[]>(`${this.resourceUrl}\/${endpoint}`, { params }).pipe(
        catchError(this.handleError)
      );
    }
  }

  /**
   * Handle HTTP errors.
   * 
   * @param error
   * 
   * @returns Observable<any[]>
   */
  private handleError(error: any): Observable<never> {
    const errorMessage = error.message ? error.message : this.translate.instant('common.errorHasOccured');
    return throwError(() => new Error(errorMessage));
  }
}
