import { Directive, ElementRef, OnInit } from '@angular/core';

@Directive({
  selector: '[appEqualHeight]'
})
export class EqualHeightDirective implements OnInit {

  constructor(private el: ElementRef) { }

  ngOnInit() {
    this.equalizeHeight();
  }

  private equalizeHeight() {
    const element = this.el.nativeElement;
    const siblings = element.parentElement.children;
    let maxHeight = 0;

    for (let i = 0; i < siblings.length; i++) {
      const sibling = siblings[i];
      if (sibling !== element) {
        const siblingHeight = sibling.offsetHeight;
        if (siblingHeight > maxHeight) {
          maxHeight = siblingHeight;
        }
      }
    }

    element.style.height = maxHeight + 'px';
  }
}
