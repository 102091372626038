import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { ApplicationConfigService } from '../../../configs/application-config.service';
import { GenericService } from '../generic/generic.service';
import { IAttributeValue } from '../../../shared/models/attribute-value/attribute-value.model';
import { IAttributeValueService } from '../../../shared/interface/attribute-value/attribute-value-interface.service';

export type EntityResponseType = HttpResponse<any>;

@Injectable({
  providedIn: 'root',
})
export class AttributeValueService extends GenericService<IAttributeValue> implements IAttributeValueService {
    constructor(
      httpClient: HttpClient,
      applicationConfigService: ApplicationConfigService
  ) {
        super(httpClient,applicationConfigService,"attribute-value");
    }
}