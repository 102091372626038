<div class="size-list-selector">
    <span>{{ 'common.show' | translate }}</span>
    <select  
        class="form-select" 
        style="width: auto" 
        [(ngModel)]="selectedPageSize"
        (change)="onSizeChange()">
        <option *ngFor="let option of pageSizeOptions" [ngValue]="option">{{ option }}</option>
    </select>
    <span>{{ listingTitle | translate }}</span>
</div>